import Script from "next/script";

export default function GoogleAnalyticsScript() {
  /* Add google analytics script if env is prod */
  if (process.env.NODE_ENV === "production") {
    return (
      <>
        <Script
          async
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=G-3QPT7F8ERN"
        />
        <Script id="google-analytics" async strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());         

            gtag('config', 'G-3QPT7F8ERN');
          `}
        </Script>
      </>
    );
  } else {
    return null;
  }
}
